import './App.scss'
import { BrowserRouter as Router, Route } from "react-router-dom";
import RootLayout from "./layout/RootLayout";
import { createBrowserHistory } from "history";


const hist = createBrowserHistory();

function App() {
  return (
    <Router>
        <RootLayout />
    </Router>
  );
}

export default App;
