import React from 'react';

const Add = () => {
    return (
        <div>
            
        </div>
    );
}

export default Add;
