import React from "react";

import { Text, Button, Box } from "native-base";

export default function Cards({ name, icon, content, onLink }) {
  return (
    <Box mx={10} mb={-5}>
      <div class="card">
        <div class="face face1" onClick={onLink}>
          <div class="content">
            <img src={icon} alt="icon" />
            <Text
              fontSize="2xl"
              textAlign="center"
              fontFamily="Questrial"
              fontWeight="medium"
              color="white"
              mt={4}
            >
              {name}
            </Text>
          </div>
        </div>
        <div class="face face2">
          <div>
            <Text fontSize="sm" color="black" textAlign="flex-start">
              {content}
            </Text>
            <Button
              width="1/2"
              position="absolute"
              alignSelf="center"
              bottom={4}
              left={'25%'}
              rounded="none"
              variant="outline"
              colorScheme="black"
              mt={2}
              onPress={onLink}
            >
              Visit
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
}
