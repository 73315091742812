import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Add from "../view/Add";
import Hub from "../view/Hub";

export default function RootLayout() {
  return (
    <Switch>
      <Route path="/hub" component={Hub} />
      <Route path="/add" component={Add} />

      <Redirect to="/hub" />
    </Switch>
  );
}
